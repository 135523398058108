import { canUseFinalsToGrade, FinalsToGrade } from '@/components/graphs/FinalsToGrade'
import GoalOneLineText from '@/components/specific/GoalOneLineText'
import useCategories from '@/hooks/useCategories'
import React from 'react'

const Right = () => {
    const { groups, categoryList } = useCategories()

    const conditionsMetForFinalsToGrade = canUseFinalsToGrade(groups, categoryList)

    if (conditionsMetForFinalsToGrade) {
        return <FinalsToGrade />
    }

    return <GoalOneLineText />
}

export default Right
