import { fetchGet } from 'src/lib/fetch'

export const castPromiseToUndefined = (promise: Promise<any>) => promise.then(() => undefined)

export const castPromiseToItself = async (promise: Promise<any>, url: string, fetcher?: (url: string) => any) => {
    return await promise.then(() => (fetcher ? fetcher(url) : fetchGet(url)))
}

export const removeNullishValues = <T extends {}>(obj: T) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v !== undefined)) as T
}
