import React from 'react'
import TalkingPanda from '../../components/TalkingPanda'
import { useHomeTutorial } from './useHomeTutorial'

const HomePanda = () => {
    const { tutorial, dialogue: dialog, handleNext, isActive, setIsActive } = useHomeTutorial()
    if (!tutorial) return <></>

    return (
        <TalkingPanda
            dialogue={dialog}
            text={dialog.text}
            onNext={handleNext}
            isActive={isActive}
            setIsActive={setIsActive}
        />
    )
}

export default HomePanda
