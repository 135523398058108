import { MS_PER_WEEK } from '@constants/date'
import { Key, Tutorial } from '../../types'
import { useTutorial } from '../../useTutorial'
import { getDialogByLevel } from './dialogues'
import { defaultHandleNext } from '../../idle'
import { useRouter } from 'next/navigation'
import { level1HandleNext } from './dialogues/level1'
import { useAppDispatch } from '@/redux/hooks'
import { useCallback } from 'react'

const shouldLevelupHome = (level: number, tutorial: Tutorial | undefined) => {
    if (!tutorial) return false
    switch (level) {
        case 0:
            return true
        case 1:
            return Date.now() > tutorial.createdAt + MS_PER_WEEK
        case 2:
            return false
        default:
            return false
    }
}

export const useHomeTutorial = () => {
    const router = useRouter()
    const dispatch = useAppDispatch()
    const { tutorial, setDialogKey, manualLevelUp, setVisited, ...props } = useTutorial('home', getDialogByLevel, shouldLevelupHome)

    const handleNext = useCallback(
        (val: Key) => {
            if (!tutorial) return
            const level = tutorial.level
            if (val === 'end' && level % 2 === 1) {
                manualLevelUp(level + 1)
                return
            }
            val.toString().trim() && val !== 'self-explore' && setVisited(val.toString())

            switch (tutorial.level) {
                case 0:
                case 1:
                    return level1HandleNext(val, setDialogKey, props.edit, { routerPush: router.push, dispatch })
                default:
                    break
            }
            return defaultHandleNext(val, setDialogKey)
        },
        [tutorial, manualLevelUp, setVisited, setDialogKey, router, dispatch, props.edit]
    )

    return {
        handleNext,
        tutorial,
        ...props,
    }
}
