import { castPromiseToItself } from '@/utils/cast'
import { useCallback, useEffect, useState } from 'react'
import { fetchGet, fetcher } from 'src/lib/fetch'
import { Dialogues, SPECIAL_DIALOGUE_KEYS, Tutorial } from './types'
import { useSWR } from '@/lib/swr'

const incrementLevel = async (url: string) => {
    return await fetcher(url, 'PATCH', {
        add: {
            level: 1,
        },
    })
}

const editTutorial = async (url: string, updates: any) => {
    return await fetcher(url, 'PATCH', updates)
}

export const useTutorial = (
    path: string,
    getDialogueByLevel: (tutorial?: Tutorial) => Dialogues,
    shouldLevelup: (level: number, tutorial: Tutorial | undefined) => boolean
) => {
    const id = path
    const url = `/api/tutorial/${id}`
    const [dialogKey, setDialogKey] = useState('0')
    const [isLoaded, setIsLoaded] = useState(false)
    const { data: tutorial, mutate } = useSWR<Tutorial>(url, {
        onSuccess: (data) => {
            if (dialogKey !== '0' || !data.current) return
            if (data.current in getDialogueByLevel(data)) setDialogKey(data.current)
        },
    })
    const [isActive, setIsActive] = useState(false)
    const dialogues = getDialogueByLevel(tutorial)
    const dialogue = dialogues[dialogKey]

    useEffect(() => {
        if (isLoaded || !tutorial) return
        if (tutorial.level === 0 || tutorial.createdAt > Date.now() - 1000 * 3) {
            setIsActive(true)
            setIsLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tutorial, setIsLoaded])

    useEffect(() => {
        if (dialogKey !== '0' || !tutorial?.current) return
        if (tutorial.current in getDialogueByLevel(tutorial)) setDialogKey(tutorial.current)
    }, [dialogKey, getDialogueByLevel, tutorial])

    // some use messages
    // need to get messages
    // MESSAGE: "Good luck on your midterm! - Christina Yang"

    const [text, setText] = useState('')

    useEffect(() => {
        if (!dialogue) console.error(`No dialogue found for key: ${dialogKey}, level: ${tutorial?.level}`)
        if (typeof dialogue.text === 'string') {
            return setText(dialogue.text)
        }
        if (typeof dialogue.text === 'function') {
            const promise = dialogue.text()
            if (promise instanceof Promise) {
                promise.then((res) => {
                    setText(res)
                })
            }
        }
    }, [dialogKey, tutorial?.level])

    // visited should be an array of booleans/numbers
    const edit = useCallback(async (updates: Partial<Tutorial>) => {
        if (!tutorial) return

        // @ts-expect-error
        const keysToRemove = Object.keys(updates).filter((key) => updates[key] === undefined)
        if (updates.level !== undefined && !keysToRemove.includes('visited')) {
            keysToRemove.push('visited') // remove visited if level is updated
        }

        const promise = editTutorial(url, {
            set: updates,
            remove: keysToRemove,
        })

        mutate(castPromiseToItself(promise, url, fetchGet), {
            optimisticData: (prev) => {
                const res = prev
                    ? {
                          ...prev,
                          ...updates,
                      }
                    : {
                          level: 0,
                          createdAt: Date.now(),
                          ...updates,
                          id: `TUTORIAL#${id}`,
                      }
                if (updates.level !== undefined) {
                    res.visited = new Set()
                }
                keysToRemove.forEach((key) => {
                    // @ts-expect-error
                    delete res[key]
                })
                return res
            },
        })
    }, [id, mutate, tutorial, url])

    const setVisited = async (key: string) => {
        if (!tutorial) return
        const promise = editTutorial(url, { add: { visited: [key] } })
        mutate(castPromiseToItself(promise, url, fetchGet), {
            optimisticData: (prev) => {
                const res = {
                    level: 0,
                    id: `TUTORIAL#${id}`,
                    createdAt: Date.now(),
                    ...prev,
                }
                if (res.visited) res.visited.add(key)
                else {
                    res.visited = new Set([key])
                }
                return res
            },
        })
        return promise
    }

    const handleLevelup = useCallback(
        (level?: number) => {
            setDialogKey('0')
            return edit({ level: level || (tutorial?.level || 0) + 1, current: undefined })
            // const promise = editTutorial(url, { set: { level: level || (tutorial?.level || 0) + 1 } });
            // // incrementLevel(url);

            // mutate(castPromiseToItself(promise, url, fetchGet), {
            //     optimisticData: (prev) => (prev ? {
            //         ...prev,
            //         level: level || (prev?.level + 1),
            //     } : {
            //         level: level || 1,
            //         id: `TUTORIAL#${id}`,
            //         createdAt: Date.now()
            //     })
            // });
            // return promise
        },
        [tutorial, edit]
    )

    const dialogueOptions = { ...dialogue, text }.responses || []
    const filteredOptions = tutorial ? dialogueOptions.filter((x) => !x.next || !tutorial.visited?.has(x.next.toString())) : []

    if (
        filteredOptions.filter((x) => !x.next || !SPECIAL_DIALOGUE_KEYS.includes(x.next.toString())).length === 0 &&
        dialogueOptions.filter((x) => !x.next || !SPECIAL_DIALOGUE_KEYS.includes(x.next.toString())).length > 0
    ) {
        // 3 special options, self-explore, veteran, reset-options
        const level = tutorial?.level || 0
        if (level % 2 === 1) {
            // handleLevelup(level + 1)
            setDialogKey('congrats')
        }
    }

    const checkLevelup = async () => {
        const level = tutorial?.level || 0

        // const usedUpAllOptions
        if (shouldLevelup(level, tutorial)) {
            await handleLevelup((level || 0) + 1)
        }
    }

    useEffect(() => {
        checkLevelup()
    }, [tutorial])

    // useEffect(() => {
    //     manualLevelUp(1)
    // }, [tutorial === undefined]);

    return {
        tutorial,
        checkLevelup,
        dialogue: { ...dialogue, text, options: filteredOptions },
        setDialogKey,
        text,
        manualLevelUp: handleLevelup,
        setVisited,
        edit,
        isActive,
        setIsActive,
    }
}
