'use client'
/**
 * BUG: https://www.reddit.com/r/reactjs/comments/y686t7/responsive_rendering_with_ssr/
 * mobile render causes hydration error
 */

import { css } from 'styled-system/css'
import React from 'react'
import { styled } from 'styled-system/jsx'

import { startOfToday } from 'date-fns'
import Image from 'next/image'

import { Button } from 'src/components/common/Button'
import PlandaTimegrid from 'src/features/calendars/common-use/Timegrid'
// import { FileTextIcon } from '@radix-ui/react-icons'
import { ActivityGraph } from '@/components/graphs/ActivityGraph'
import { Flex } from '@planda/design-system'
import { useWindowSize } from '@/hooks/useWindowWidth'
import HomePanda from 'src/features/easter-eggs/cutscenes/home/HomePanda'
import Arrow from '@/components/common/arrow/Arrow'
import { useGetUserQuery } from '@/redux/features/api'
import FAQ from '@/components/specific/FAQ'
import Left from '@/app/(auth)/home/components/Left'
import { useAppSelector } from '@/redux/hooks'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import Right from './components/Right'

const Streak = dynamic(() => import('@/app/(auth)/home/components/Streak'))

// const start = startOfWeek(Date.now(), { weekStartsOn: 1 })
// const multiDay = range(1, 5).map(i => {
//   const dateStart = addDays(start, i)
//   return {
//     dateStart: dateStart,
//     dateEnd: addDays(dateStart, i + 1), // 1 2 3 4 5 n*(n+1)/2
//     name: 'multi-event ' + i,
//     id: 'multi-event ' + i,
//     color: { bg: '$categorybg' + (i + 1), txt: '$categorytxt' + (i + 1) },
//   }
// })

// const ListIcon = styled(FileTextIcon, {

// TODO: test recurring (prob doesn't work)
// const crons = ['8 0 ? * 1,4 * 87000000', '0 15 ? * 2 * 43200000', '0 23 ? * 5 * 9900000', '0 16 ? * 3 * 4500000']
// const recurring = range(4).map(i => {
//   return {
//     dateStart: Date.now() - MS_PER_30_DAYS,
//     dateEnd: Date.now() + MS_PER_30_DAYS, // 1 2 3 4 5 n*(n+1)/2
//     cron: crons[i],
//     name: 'r-event ' + i,
//     id: 'r-event ' + i,
//     color: { bg: '$categorybg' + (i + 1), txt: '$categorytxt' + (i + 1) },
//   }
// })

// const events = range(4).map(i => {
//   const dateStart = addDays(start, i)
//   return {
//     dateStart,
//     dateEnd: addHours(dateStart, i + 1), // 1 2 3 4 5 n*(n+1)/2
//     name: 'event ' + i,
//     id: i.toString(),
//     color: { bg: '$categorybg' + (i + 1), txt: '$categorytxt' + (i + 1) },
//   }
// })

// const all = [...multiDay, ...events]

const Home = () => {
    const viewMode = useAppSelector((state) => state.viewMode.mode)
    // const { viewMode } = useDemo('demo')
    const { data: user } = useGetUserQuery()

    return (
        <>
            <Page>
                <Header>
                    <HeaderInner>
                        HOME
                        {user?.style !== 'plain' && (
                            <PandaContainer>
                                <Image src={'/planda_header_icon.png'} alt="panda-image" className={css({ objectFit: 'contain' })} fill />
                            </PandaContainer>
                        )}
                        <Streak />
                    </HeaderInner>
                </Header>
                <PlandaTimegrid
                    containerClassName={css({ padding: '20px 0px 0px 20px' })}
                    firstDay={startOfToday().getTime()}
                    days={1}
                    disableScroll={viewMode === 'demo'}
                />
                <MainSectionHome />
            </Page>
        </>
    )
}
Home.auth = true

export default Home

const MainSectionHome = () => {
    const { isMobile, windowSize } = useWindowSize('mobile')

    if (isMobile) return null
    return (
        <>
            <Main>
                <CenterRow>
                    {windowSize.width > 1345 ? (
                        <SideContainer>
                            <Left />
                        </SideContainer>
                    ) : (
                        <div></div>
                    )}
                    <Flex direction={'column'} gap={'5'} align={'center'}>
                        Welcome back!
                        <NavBar />
                    </Flex>
                    {windowSize.width > 1345 && (
                        <SideContainer>
                            <Right />
                        </SideContainer>
                    )}
                </CenterRow>
                <Flex justify={'center'}>
                    {(!windowSize.width || windowSize.width > 800) && <ActivityGraph type="completed" width={500} title="Tasks Completed" />}
                    {(!windowSize.width || windowSize.width > 1345) && <ActivityGraph type="time" width={500} title="Productive Hours" />}
                </Flex>
            </Main>
            <HomePanda />
        </>
    )
}

// dynamic(() => Promise.resolve(Home), {
//   // ssr: false
// })
// export default Home

const Page = styled('div', {
    base: {
        height: '100vh',
        margin: '0',
        boxSizing: 'border-box',
        flex: 1,
        background: '$primary1',
        gridGap: '0',
        display: 'grid',
        gridTemplateColumns: 'minmax(280px, 1fr) 3fr',
        gridTemplateRows: '1.35fr 5fr',
        gridTemplateAreas: `
    "calendar header"
    "calendar main"
    `,
        overflowX: 'scroll',
    },
})

const SideContainer = styled('div', {
    base: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        // flex: 1,
    },
})

const Header = styled('header', {
    base: {
        position: 'relative',
        display: 'flex',
        gridArea: 'header',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '45px',
        backgroundColor: '$homebg',
        boxSizing: 'border-box',
        padding: '16px 0px',
        gap: 'min(50px, 5%)',
    },
})

const HeaderInner = styled('div', {
    base: {
        display: 'flex',
        gridArea: 'header',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '45px',
        boxSizing: 'border-box',
        gap: 'min(50px, 5%)',
        minHeight: 50,
        maxHeight: '80%',
        height: 92,
        width: '100%',
    },
})

const PandaContainer = styled('div', {
    base: {
        position: 'relative',
        display: 'inline-block',
        height: '100%',
        aspectRatio: '400/300',
    },
})

const Main = styled('div', {
    base: {
        display: 'flex',
        flexDirection: 'column',
        gridArea: 'main',
        fontSize: '30px',
        backgroundColor: '$primary1',
        textAlign: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '47px 35px',
    },
})

const CenterRow = styled('div', {
    base: {
        width: '100%',
        position: 'relative',
        display: 'grid',
        boxSizing: 'border-box',
        gridTemplateColumns: '1fr auto 1fr',
        gridTemplateAreas: '. nav .',
        alignItems: 'center',
        gap: 10,
        padding: 15,
        paddingBlock: '0px 30px',
    },
})

const Navigation = styled('div', {
    base: {
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        width: '18rem',
        gap: 10,
        padding: 15,
        justifySelf: 'center',
    },
})

const NavLabel = styled('div', {
    base: {
        boxSizing: 'border-box',
        fontSize: '20px',
        padding: '10px',
        color: '$gray12',
    },
})

const SideBar = styled('div', {
    base: {
        gridArea: 'sidebar',
        backgroundColor: '$primary3',
        overflow: 'overlay',
        '&::-webkit-scrollbar': {
            backgroundColor: 'rgba(0,0,0,0)',
            width: '16px',
            height: '16px',
            zIndex: 999999,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0,0,0,0)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0)',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-button': {
            display: 'none',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: '$primary6',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '$primary7',
        },
    },
})

const NavBar = () => {
    const { data: user } = useGetUserQuery()
    const tutorialArrow = useAppSelector((state) => state.temporaryState.tutorialArrow)

    return (
        <Navigation>
            <Button as={Link} className={css({ backgroundColor: '$secondary4' })} color={'secondary4'} href="/list">
                <NavLabel>Tasks</NavLabel>
                {tutorialArrow === 'list' && <Arrow direction="right" />}
            </Button>
            {user?.mode !== 'simplified' && (
                <Button as={Link} className={css({ backgroundColor: '$secondary5' })} color={'secondary5'} href="/week">
                    <NavLabel>Week</NavLabel>
                </Button>
            )}
            {user?.mode !== 'simplified' && (
                <Button as={Link} className={css({ backgroundColor: '$secondary6' })} color={'secondary6'} href="/month">
                    <NavLabel>Month</NavLabel>
                    {tutorialArrow === 'month' && <Arrow direction="right" />}
                </Button>
            )}
            {user?.mode === 'simplified' && <FAQ />}
        </Navigation>
    )
}
