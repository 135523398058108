import { z } from 'zod'

export const TutorialSchema = z.object({
    level: z.number(),
    id: z.string(),
    createdAt: z.number(),
    visited: z.set(z.string()).optional(),
    current: z.string().optional(),
})

export type Tutorial = z.infer<typeof TutorialSchema>

export type Key = number | string
export interface Reply {
    next?: Key
    reply: string
    onClick?: (setIsActive: (x: boolean) => void) => void
}

export interface Dialogue {
    text: string | (() => string | Promise<string>)
    next?: Key
    responses?: Reply[]
    requiresAction?: boolean
    onClick?: (setIsActive: (x: boolean) => void) => void
}

export type Dialogues = Record<string, Dialogue>

export const SPECIAL_DIALOGUE_KEYS = ['end', 'congrats', '', 'self-explore', 'veteran', 'reset-options']
