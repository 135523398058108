import useCategories from '@/hooks/useCategories';
import { sum } from 'lodash';
import { css } from 'styled-system/css';
import dynamic from 'next/dynamic';
import { useMemo } from 'react';
import { Category, TodoGroup } from 'src/types';
const ResponsiveLine = dynamic(() => import("@nivo/line").then(m => m.ResponsiveLine), { ssr: false });

interface PlotBounds { lowerX: number, upperX: number, lowerY?: number, upperY?: number }
const generateLinearData = (slope: number, yIntercept: number, bounds: PlotBounds, step: number) => {
    const { lowerX, upperX } = bounds
    const data = []
    for (let x = lowerX; x <= upperX; x += step) {
        const y = slope * x + yIntercept
        if (bounds.lowerY !== undefined && y < bounds.lowerY) continue
        if (bounds.upperY !== undefined && y > bounds.upperY) continue
        data.push({ x, y })
    }
    return data
}

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const FinalsToGrade = () => {
    const { groups, categoryList } = useCategories()
    // for each category, find all groups, if all groups have grade, make it show plot

    const categoriesToPlot = useMemo(() => {
        if (!groups || !categoryList) return []
        const categories = getGradedCategories(groups, categoryList)
        return categories.map(c => {
            const yIntercept = groups[c.id].reduce((acc, g) => {
                if (g.gradeInPercentage === undefined || g.gradingWeightInPercentage === undefined) return acc
                return acc + g.gradeInPercentage * g.gradingWeightInPercentage / 100
            }, 0)
            const slope = groups[c.id].reduce((acc, g) => {
                if (!(g.gradeInPercentage === undefined && g.gradingWeightInPercentage !== undefined)) return acc
                return acc + g.gradingWeightInPercentage / 100
            }, 0)
            return {
                "id": c.name,
                // "color": "hsl(38, 70%, 50%)",
                "data": generateLinearData(slope, yIntercept, { lowerX: 0, upperX: 100, lowerY: 0, upperY: 100 }, 5)
            }
        })
    }, [categoryList, groups])

    if (categoriesToPlot.length === 0) return <></>

    return (
        <div className={css({ width: '100%', height: 180, })} >
            <ResponsiveLine
                data={categoriesToPlot}
                // margin={{ top: 5, right: 110, bottom: 20, left: 50 }}
                margin={{ top: 5, right: 110, bottom: 45, left: 60 }}
                xScale={{ type: 'linear', min: 0, max: 100 }}
                yScale={{ type: 'linear', min: 0, max: 100 }}
                useMesh={true}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Grade on Final (%)',
                    legendOffset: 36,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Final Grade (%)',
                    legendOffset: -40,
                    legendPosition: 'middle'
                }}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
            />
            {/* {total !== 100 && <sub>Note: adds up to {total}%</sub>} */}
        </div>
    )
}

const getGradedCategories = (groups?: Record<string, TodoGroup[]>, categoryList?: Category[]) => {
    if (!groups || !categoryList) return []
    const categories = categoryList.filter(c => {
        if (!c) return false // happens right after you delete a category
        if (!groups[c.id] || groups[c.id].length === 0) return false
        if (!groups[c.id].some(g=>g.name.toLowerCase().includes("final") && g.gradeInPercentage === undefined && g.gradingWeightInPercentage !== undefined)) return false
        const total = Math.round(sum(groups[c.id].map(x => x.gradingWeightInPercentage)))
        if (total < 98) return false
        return groups[c.id].every(g => g.name.toLowerCase().includes("final") || !g.gradingWeightInPercentage || !!g.gradeInPercentage)
    })
    return categories
}

export const canUseFinalsToGrade = (groups?: Record<string, TodoGroup[]>, categoryList?: Category[]) => {
    const categories = getGradedCategories(groups, categoryList)
    return categories.length > 0
}
